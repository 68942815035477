class Cart {
    constructor(userOptions) {
        this.initialOptions = {
            selector: '[data-cart]'
        };
        this.options = $.extend({}, this.initialOptions, userOptions);
        this.init();
    }

    init() {
        this.parseMarkup();
        this.updateItems();
        this.spinnerHandler();
        this.removeHandlers();
    }

    updateItems() {
        this.cleanList();
        this.getRemoteList();
    }

    renderItems(itemsData) {
        let
            _Cart = this,
            $template = _Cart.structure.$itemTemplate;
        $.each(itemsData, function (i, itemData) {
            let $item = $template.clone();
            $item.data('count', itemData['count']);
            $item.data('price', itemData['price']);
            $item.data('product-id', itemData['id']);
            $item.find('[data-field]').each(function (i, field) {
                let
                    $field = $(field),
                    params = $field.data('params'),
                    key = $field.data('field');
                if (params) {
                    $.each(params, function (param, value) {
                        switch (param) {
                            case 'html':
                                $field.html(itemData[key]);
                                break;
                            case 'bgi':
                                $field.css('background-image', `url(${itemData[value]})`)
                                break;
                            default:
                                let newValue = itemData[value]
                                if (newValue) {
                                    $field.attr(param, newValue)
                                } else {
                                    $field.remove();
                                }

                        }
                    })
                } else {
                    if ($field.is('input')) {
                        $field.val(itemData[key])
                    } else {
                        $field.html(itemData[key])
                    }
                }
            });
            $item.appendTo(_Cart.structure.$list).fadeIn();
            _Cart.recalculateItem($item);
        });
        _Cart.recalculateTotal();
    }

    parseMarkup() {
        let
            _Cart = this,
            structure = {
                $wrapper: $(_Cart.options.selector),

            };
        structure.$list = structure.$wrapper.children('[data-cart-list]');
        structure.$itemTemplate = structure.$list.children('[data-item-template]').clone().removeAttr('data-item-template');
        structure.$totalAmount = structure.$wrapper.find('[data-cart-total]');
        _Cart.structure = structure;
    }

    cleanList() {
        this.structure.$list.empty();
    }

    getRemoteList() {
        let _Cart = this;

        $.ajax({
            url: _Cart.structure.$wrapper.data('cart').items_url,
            type: _Cart.structure.$wrapper.data('cart').type,
            dataType: 'json',
            data: {},
            success: function (result) {
                _Cart.renderItems(result.data)
            },
            complete: function () {
            },
            error: function () {
            }

        })
    }

    spinnerHandler() {
        let _Cart = this;

        _Cart.structure.$wrapper.on('click', '[data-item-spinner]>button', function (e) {
            let
                $button = $(this),
                action = $button.attr('data-spin'),
                $input = $button.siblings('input'),

                curVal = parseInt($input.val(), 10) || 0;

            if (action == 'more') {
                $input.val(curVal += 1);
            } else if (action == 'less') {
                $input.val(curVal -= 1);
            }
            $input.trigger('change');
            // $input.focus();
        }).on('change', '[data-item-spinner]>input', function (e) {
            let
                $input = $(this),
                $item = $input.closest('[data-cart-item]'),
                count = parseInt($input.val(), 10);
            count = count < 1 ? 1 : count;

            $input.val(count);
            $item.data('count', count);
            _Cart.recalculateItem($item, true);
        });
    }

    recalculateItem($item, updateTotal) {
        let
            price = $item.data('price'),
            newCount = $item.data('count');
        $item.find('[data-price]').each(function (i, price) {
            let $price = $(price);
            $price.text(formatDigits($price.data('price') * newCount))
        });

        $item.data('sum', newCount * price);
        if (updateTotal) {
            this.recalculateTotal()
        }
    }

    recalculateTotal() {
        let
            _Cart = this,
            totalAmount = 0;
        _Cart.structure.$list.children().each(function (i, el) {
            totalAmount += $(el).data('sum')
        })
        _Cart.structure.$wrapper.data("totalAmount", totalAmount);
        _Cart.structure.$totalAmount.text(formatDigits(totalAmount));

    }
    removeHandlers(){
        let
            _Cart = this;
        _Cart.structure.$list.on('click','[data-remove]',function(){
            let
                $item = $(this).closest('[data-cart-item]');
            $.ajax({
                url: _Cart.structure.$wrapper.data('cart').items_url,
                type: _Cart.structure.$wrapper.data('cart').type,
                data: {
                    id:$item.data('product-id')
                },
                success: function (result) {
                    $item.slideUp(function(){
                        $item.remove();
                        setGlobalCounter('cart', 'remove')
                        _Cart.recalculateTotal();
                    });
                },
                complete: function () {
                },
                error: function () {
                }
            })
        });
    }
    refreshItems(){
        this.cleanList()
        this.getRemoteList()
    }
}
if($('[data-cart-inline]').length){
    window.oCart = new Cart({
        selector:'[data-cart-inline]'
    });
}
window.oCartPopup = new Cart({
    selector:'[data-cart-popup]'
});

/**
 * Разделение числа по разрядам
 * @param number
 * @param {string} [delimiter=&nbsp;] - Разделитель
 * @returns {string}
 */
function formatDigits(number, delimiter) {
    delimiter = delimiter || ' ';
    return (number + '').replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delimiter);
}
